@import "../../utilities/variables.scss";

.footer {
  box-sizing: border-box;
  width: 100vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-color: black;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  font-size: 13px;
  opacity: 1;
  color: white !important;

  .Mail-Footer {
    align-self: flex-end;
  }

  .left-side {
    display: flex;

    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 5vh;
  }

  ul {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .Svg-Link {
      margin-top: 20px;
      height: 20px;
    }

    svg {
      height: 100%;
    }
  }

  @media (max-width: $lg) {
    justify-content: flex-start;
    flex-direction: column;
    gap: 50px;

    padding-top: 5vh;

    padding-bottom: 5vh;

    .left-side {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        svg {
          max-width: 50vw;
        }
      }

      div {
        display: none;
      }
    }

    ul {
      flex-wrap: wrap;

      li {
        white-space: nowrap;
      }

      div {
        display: none;
      }

      flex-direction: row;
    }

    .Mail-Footer {
      align-self: flex-start;
    }
  }
}
