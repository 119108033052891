@import "../../utilities/variables.scss";
.LegalPage {
  min-height: 100vh;
  display: flex;
}

.LegalPage-Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LegalPage-Content {
  margin-bottom: 2.5vh;
  margin-top: 10vh;

  p {
    font-size: 1.25em;
    max-width: 500px;
  }
}

.LegalPage-Kontakt {
  font-size: 1.5rem;
  font-family: monospace;
}
