:root {
  --grey: rgb(181, 181, 181);
  --white: #ffffff;
}

$xxl: 2500px;
$xl: 1300px;
$lg: 1050px;
$md: 768px;
$sm: 576px;
$xs: 370px;
