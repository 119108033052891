@import "../../utilities/variables.scss";

.StartPage {
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StartPage-Content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: StartPage-Content-Animation;
  animation-duration: 1s;

  animation-fill-mode: both;

  @keyframes StartPage-Content-Animation {
    0% {
      opacity: 0;
      transform: translateY(0px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  p {
    max-width: 500px;
    text-align: center;
  }

  h1 {
    font-size: 15rem;
    letter-spacing: 30px;
  }

  img {
    width: 800px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  a {
    align-self: center;
    height: fit-content;
  }
}

.StartPage-Button {
  font-weight: bold;
  border-width: 0;
  font-size: 1.5rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  transition: transform 200ms;

  &:hover {
    transform: scale(1.1);
  }
}

.StartPage-Button-Container {
  margin-top: 5em;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    height: 15px;
  }
}

.Start-Footer {
  width: 100%;
}
