@import "/src/utilities/variables.scss";

// fonts/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v25-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/montserrat-v25-latin-regular.woff") format("woff"), /* Modern Browsers */ url("../fonts/montserrat-v25-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/montserrat-v25-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v25-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/montserrat-v25-latin-700.woff") format("woff"), /* Modern Browsers */ url("../fonts/montserrat-v25-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/montserrat-v25-latin-700.svg#Montserrat") format("svg"); /* Legacy iOS */
}
