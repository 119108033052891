@import "../../utilities/variables.scss";

.Home {
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Home-Background {
  display: none;
  -webkit-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
}

.Home-Content-Container {
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $lg) {
  }
}

.Header-Background {
  display: none;
  position: absolute;
  font-size: 20rem;
  white-space: nowrap;
  opacity: 0.05;
  -webkit-filter: blur(7px);
  pointer-events: none;
}

.Painting-Button {
  margin-top: 1.75em;
  position: relative;

  a {
    display: flex;
    align-items: center;
    width: fit-content;
    transition: transform 250ms;

    &:hover {
      transform: scale(1.1);
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.25em;
  }

  svg {
    transform: rotate(180deg);
    height: 30px;
  }
}

.Bottom-Bar {
  padding-bottom: 30px;
  margin-top: 50px;
  opacity: 0.3;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  .Bottom-Bar-Links {
    display: flex;
    gap: 10px;
  }
}

.Home-Title {
  margin-top: 10px;

  position: relative;

  p {
    white-space: wrap;
  }

  h1 {
    font-size: 4vw;

    width: fit-content;
    max-width: 1200px;
    white-space: pre-wrap;

    @media screen and (max-width: $lg) {
      font-size: 3em;
      max-width: 800px;
    }
  }
}

.Home-Swiper {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.swiper {
  margin: 0;
  overflow: visible;
}

.swiper-wrapper {
}

.Swiper-Content {
  box-sizing: border-box;
  padding: 20px 35px 20px 35px;
  display: flex;
  transition: opacity 1s, transform 1s;
  transform: translateY(100%);

  background-color: black;

  bottom: 0;
  width: 100%;
  flex-direction: column-reverse;
  position: absolute;
  z-index: 100;
  color: white !important;

  @media (max-width: $lg) {
    padding: 10px 15px 10px 15px;
  }
}

.swiper-slide {
  transition: transform 1s, filter 1s !important;
  transform: scale(1) rotate(-2deg) translateY(0px) !important;
  filter: saturate(0);
  overflow: hidden;
  width: 50vw;
  position: relative;
  min-width: 1px;
  height: 65vh;
  min-height: 500px;
  opacity: 1;

  &.swiper-slide-active,
  &.swiper-slide-duplicate-active {
    .Swiper-Content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.swiper-slide-next {
    transform: rotate(-2deg) translateY(0px) !important;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 1s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &:active {
    transition: transform 100ms;
    transform: scale(0.9) !important;
  }

  @media (max-width: $lg) {
    width: 100vw !important;
  }
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  filter: blur(0px);
  opacity: 1;
  filter: saturate(1);
  transform: scale(1) !important;

  img {
    transition: all 1s;
  }

  .border {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 0px white solid;
    position: absolute;
  }
}

.swiper-navigation {
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;

  border-radius: 50%;
  background: url("../../img/arrow.png") no-repeat;
  opacity: 1;
  background-size: 100% 100%;
  color: rgb(109, 109, 109);

  &:after {
    display: none;
    content: "";
  }
}

.swiper-button-prev {
  transform: rotate(90deg);
  left: 20px;
}

.swiper-button-next {
  transform: rotate(-90deg);
  right: 20px;
}

.custom-pagination {
  margin: auto 0;
  margin-top: 50px;
  bottom: 0px !important;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 5px;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .swiper-pagination-bullet-active {
    background-color: rgb(0, 0, 0);
  }
}

.Blog {
  h1 {
    text-decoration: underline;
  }

  .Blog-Entries {
    margin-top: 5vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: $lg) {
      grid-template-columns: 1fr;
    }

    li {
      position: relative;
      height: 500px;
      overflow: hidden;

      .Blog-Title {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        position: absolute;
        color: white;

        pointer-events: none;

        h2 {
          padding-left: 5%;
          padding-bottom: 5%;
          z-index: 10;
        }

        .gradient {
          z-index: 5;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        }
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: transform 1s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  margin-top: 5vh !important;
  display: grid;
}

.Marquee {
  h1 {
    margin-right: 50px;
  }
  margin-top: 10vh;
}

.About {
  margin-top: 10vh !important;

  .About-Container {
    display: flex;
    gap: 150px;
    position: relative;
    margin-bottom: 10vh;

    img {
      z-index: 0;
      object-fit: cover;
      bottom: -20%;
      right: 0;
      height: 200%;
      width: 65%;
      opacity: 0.8;
      position: absolute;
      transform: rotate(0deg);
      filter: saturate(0);
    }

    div {
      z-index: 10;
      p {
        padding: 50px 20px 50px 0px;
        z-index: 10;
        background-color: white;
        max-width: 700px;
      }
    }

    @media (max-width: $lg) {
      gap: 0;
      flex-direction: column;

      div > p {
        padding-bottom: 0;
      }

      .Back-Button {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 400px;
        position: relative;
      }
    }
  }

  h1 {
    text-decoration: underline;
  }
}
